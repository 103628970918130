import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './App.css';

import ReactDOM from 'react-dom';
import {HashRouter, Route} from 'react-router-dom';

import ScrollToTop from './ScrollToTop';
import {App} from './app';

ReactDOM.render(
		<HashRouter>
			<ScrollToTop>
				<Route path="/" component={App}/>
			</ScrollToTop>
		</HashRouter>,
		document.getElementById('root')
);
