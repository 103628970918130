import {Api} from './api';

export class InvoiceService {

	getIntroduction() {
		return Api.get(`/iam-system/product-introduction/view`).then(res => res.data);
	}

	getHeaderInfo(referenceNumber) {
		return Api.get(`/iam-entry/invoices/${referenceNumber}/header-info`).then(res => res.data);
	}

}
