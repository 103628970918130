import {useEffect, useState} from 'react';
import {Link, Route} from 'react-router-dom';

import {Dialog} from 'primereact/dialog';

import {InvoiceService} from '../service/InvoiceService';

import {TraCuu} from './tra-cuu';

const invoiceService = new InvoiceService();

export const App = () => {

	const [displayAds, setDisplayAds] = useState('');

	useEffect(() => {
		invoiceService.getIntroduction().then(data => {
			if (data.description) {
				setDisplayAds(data.description);
			}
		}).catch(() => {
		});
	}, []);

	return (
		<div className="App">
			<div className="layout-topbar">
				<div className="flex align-items-center justify-content-between">
					<Link to="/" className="layout-topbar-logo">
						<img src={'/assets/my-software/logo-mysoftware.png'} alt="logo" width="180px"/>
					</Link>
				</div>
			</div>

			<div>
				<Route path="/" exact component={TraCuu}/>
			</div>

			<div className="layout-footer text-center text-white p-5">
				<b>CÔNG TY CỔ PHẦN MY SOFTWARE</b>
				<p><i className="pi pi-map-marker mr-2"/>Địa chỉ: Số 18, ngõ 99/158/26 đường Định Công, Phường Định Công, Quận Hoàng Mai, TP Hà Nội</p>
				<p><i className="pi pi-phone mr-2"/><b>Hotline:</b> 0941996979</p>
				<p><i className="pi pi-envelope mr-2"/><b>Email:</b> info@mysoftware.vn</p>
				<p><i className="pi pi-envelope mr-2"/><b>Website:</b> mysoftware.vn</p>
			</div>

			<Dialog visible={!!displayAds} style={{width: '40rem'}} onHide={() => setDisplayAds('')}>
				<div className="grid">
					<div className="col-12">
						<div dangerouslySetInnerHTML={{__html: displayAds}}></div>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
